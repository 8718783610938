import React from "react"
import Scroll from "./scroll"

const ScrollLink = ({
  content,
  href,
  tabIndex,
  children,
}: {
  content?: string
  href: string
  tabIndex: number
  children?: React.ReactNode
}) => (
  <Scroll type="id" element={href} offset={-50} tabIndex={tabIndex}>
    <a href={`#${href}`}>
      {content}
      {children}
    </a>
  </Scroll>
)

export default ScrollLink
