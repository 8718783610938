import React from "react"
import containerStyles from "./section.module.scss"
import FadeDirections from "./fadeDirections"

interface Props {
  direction: FadeDirections
  color?: string
  children: React.ReactNode
  id?: string
}

const FadeInSection = (props: Props) => {
  const [isVisible, setVisible] = React.useState(false)
  const domRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    const current = domRef.current
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setVisible(entry.isIntersecting)
        }
      })
    })
    if (null !== current) {
      observer.observe(current)
    }
    return () => {
      if (null !== current) {
        observer.unobserve(current)
      }
    }
  }, [])
  let className = containerStyles.section + " "
  switch (props.direction) {
    case FadeDirections.top:
      className += containerStyles.fadei_in_top + " "
      break
    case FadeDirections.right:
      className += containerStyles.fadei_in_right + " "
      break
    case FadeDirections.left:
      className += containerStyles.fadei_in_left + " "
      break
    default:
      break
  }

  className += isVisible
    ? containerStyles.fade_in_section + ` ` + containerStyles.is_visible
    : containerStyles.fade_in_section
  return (
    <section
      id={props.id}
      style={{
        backgroundColor: `${props.color}`,
      }}
    >
      <div className={`${className}`} ref={domRef}>
        <div className={containerStyles.box}>{props.children}</div>
      </div>
    </section>
  )
}

export default FadeInSection
