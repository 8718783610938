import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = ({ path }: { path: string }) => {
  const data = useStaticQuery(
    graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )
  const image = data.images.edges.find(
    (n: { node: { relativePath: string | string[] } }) => {
      return n.node.relativePath.includes(path)
    }
  )
  return <Img fluid={image.node.childImageSharp.fluid} />
}

export default Image
