import React from "react"
import Scrollspy from "react-scrollspy"
import containerStyles from "./navbar.module.scss"

import ScrollLink from "./scrollLink"

interface Props {
  tabs: Tab[]
}

interface Tab {
  content: string
  href: string
}
const Navbar = ({ tabs }: Props) => {
  return (
    <section className={containerStyles.navbar}>
      <div className={containerStyles.inner}>
        <div className={containerStyles.hiddenBack}>
          <Scrollspy
            items={["top"]}
            currentClassName={containerStyles.backActive}
            offset={-55}
          >
            <li className={containerStyles.back}>
              <ScrollLink tabIndex={0} href="top" content="">
                <div className={containerStyles.topLink}>
                  <i className={containerStyles.arrowUp} />
                </div>
              </ScrollLink>
            </li>
          </Scrollspy>
        </div>
        <nav className={containerStyles.nav}>
          <Scrollspy
            items={tabs.map(s => s.href)}
            currentClassName={containerStyles.active}
            offset={-55}
            componentTag="div"
            className={containerStyles.row + " row around-xs"}
          >
            {tabs.map((tab, index) => (
              <div key={tab.href} className={containerStyles.navItem}>
                <ScrollLink key={tab.href} tabIndex={index} {...tab} />
              </div>
            ))}
          </Scrollspy>
        </nav>
      </div>
    </section>
  )
}

export default Navbar
