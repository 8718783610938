import smoothscroll from "smoothscroll-polyfill"
import React from "react"

const Element = (props: any) => {
  return props.children
}

interface Props {
  tabIndex: number
  type: string
  element: string
  offset: number
  timeout?: number
  children: any
}

class Scroll extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }
  componentDidMount() {
    smoothscroll.polyfill()
  }
  handleClick(e: { preventDefault: () => void }) {
    e.preventDefault()
    let scroll = true
    let elem = null
    const { type, element, offset, timeout } = this.props
    if (this.props.type && this.props.element) {
      switch (type) {
        case "class":
          elem = document.getElementsByClassName(element)[0]
          scroll = elem ? true : false
          break
        case "id":
          elem = document.getElementById(element)
          scroll = elem ? true : false
          break
        default:
      }
    }
    if (elem != null && scroll) {
      this.scrollTo(elem, offset, timeout)
    }
  }
  scrollTo(element: Element, offSet = 0, timeout?: number) {
    const elemPos = element
      ? element.getBoundingClientRect().top + window.pageYOffset
      : 0
    if (timeout) {
      setTimeout(() => {
        window.scroll({ top: elemPos + offSet, left: 0, behavior: "smooth" })
      }, timeout)
    } else {
      window.scroll({ top: elemPos + offSet, left: 0, behavior: "smooth" })
    }
  }
  render() {
    return (
      <Element>
        {typeof this.props.children === "object" ? (
          React.cloneElement(this.props.children, { onClick: this.handleClick })
        ) : (
          <span
            role="button"
            onClick={this.handleClick}
            onKeyDown={this.handleClick}
            tabIndex={this.props.tabIndex}
          >
            {this.props.children}
          </span>
        )}
      </Element>
    )
  }
}

export default Scroll
