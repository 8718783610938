import React from "react"
import myimage from "src/images/black-and-white-mountain-over-yellow-white-and-blue-sky-46253.jpg"
import containerStyles from "./intro.module.scss"
interface Props {
  title: string
  subtitle: string
}

const Intro = ({ title, subtitle }: Props) => (
  <div id="top" className={containerStyles.intro}>
    <div
      style={{ backgroundImage: `url(${myimage})` }}
      className={containerStyles.intro__image}
    />
    <div
      style={{
        margin: 0,
        position: `absolute`,
        top: `50%`,
        left: `50%`,
        msTransform: `translate(-50%, -50%)`,
        transform: `translate(-50%, -50%)`,
      }}
    >
      <h1 className={containerStyles.intro__title}>{title}</h1>
      <hr className={containerStyles.intro__division} />
      <h2 className={containerStyles.intro__subTitle}>{subtitle}</h2>
    </div>
  </div>
)

export default Intro
